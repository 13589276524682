const BASE_URL = 'https://farmer.bot.biz.ua'; // Замените на базовый URL вашего сервера

export const fetchData = async (endpoint, fetchObject = {}) => {
    const options = {
        method: 'POST',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", 
        },
        body: JSON.stringify(fetchObject)
      }
  try {
    const response = await fetch(`${BASE_URL}/${endpoint}`, options);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export function generateRandomToken(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let token = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      token += characters.charAt(randomIndex);
    }
  
    return token;
  }

  export function millisToMinutesAndSeconds(millis) {
    let minutes = Math.floor(millis / 60000);
    let seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + "min" + " : " + (seconds < 10 ? '0' : '') + seconds + "sec";
  }