import React,{useState,useContext,useEffect} from 'react';
import styles from './troops.module.css'
import {fetchData, generateRandomToken,millisToMinutesAndSeconds}  from '../../api'
function Troops() {
  const [getTroops, setTroops] = useState('barracks')
  const [troopsDbTrain, setTroopsDbTrain] = useState('')
  const [troopsDb, setTroopsDb] = useState([])
  const [selectedOption, setSelectedOption] = useState('100%');
  const [chooseTroops, setChooseTroops] = useState(['barracks', 'cavalry', 'siege_weapons'])
  const getDataTroops = async () => {
    const result = await fetchData("getTroopsData", {'typeTroops': getTroops,token:localStorage.getItem('token_Account_bot'), village:localStorage.getItem('village')})
    console.log('result',result)
    if(result){
      if(result?.train?.length){
        const troops_training = result.train.split(',')[0]
        const troops_training_procent = result.train.split(',')[1]
        setTroopsDbTrain(troops_training)
        setSelectedOption(troops_training_procent)
      }
      setTroopsDb(result)
    }
  }
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  useEffect(() => {
    getDataTroops()
  },[getTroops])

  const clickChooseTroops = (item) => {
    setTroops(item)
  }
  const setTroopsDB = async () => {
    const result = await fetchData("setTroopsDB", {'typeTroops': getTroops,token:localStorage.getItem('token_Account_bot'), village:localStorage.getItem('village')})
    if(result){
      setTroopsDb(result)
    }
  }

  const chooseTroopsTrain = async (item,idx) => {
    setTroopsDbTrain(item.name)
  }
  const sendTroopsTrainFetch = async () => {
    if(troopsDbTrain !== '' && selectedOption !== ''){

      const result = await fetchData("trainTroops", {'train': troopsDbTrain,typeTroops:getTroops,token:localStorage.getItem('token_Account_bot'), village:localStorage.getItem('village'), count_train:selectedOption})
      window.location.reload()
    }

  }
  const sendTroopsTrainFetchStop = async () => {

    const result = await fetchData("stoptrainTroops", {typeTroops:getTroops,token:localStorage.getItem('token_Account_bot'), village:localStorage.getItem('village')})
    window.location.reload()
  }

  return (
    <div>
        <h3 style={{textAlign:"center", marginBottom:15}}>Select troop type</h3>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-around", height:40, marginBottom:10}}>
        {console.log(chooseTroops)}
        {chooseTroops.map((item, idx) => {
          
          if(getTroops === item){
            return (<div key={idx} style={{display:"flex",paddingLeft:5, paddingRight:5, flexDirection:"row", justifyContent:"center", alignItems:"center", backgroundColor:"black", color:"white", borderRadius:3, marginBottom:5, }}>{item}</div>)
          }else{
            return (<div onClick={() => clickChooseTroops(item)} key={idx} style={{display:"flex",paddingLeft:5, paddingRight:5, flexDirection:"row", justifyContent:"center", alignItems:"center", backgroundColor:"white",border:"2px solid black", color:"black", borderRadius:3, marginBottom:5}}>{item}</div>)
          }
        })}
    </div>
    {troopsDb[getTroops] !== undefined && JSON.parse(troopsDb[getTroops]) !== null && JSON.parse(troopsDb[getTroops]).length !== 0 
     ? 
    //  <h3 style={{textAlign:"center", marginBottom:15}}>Select troop</h3>
     JSON.parse(troopsDb[getTroops]).map((item,idx) =>{
      console.log(troopsDbTrain)
      console.log(item)
      if(item.name === troopsDbTrain){
        return (     <>
          <div onClick={() => chooseTroopsTrain(item, idx)} key={idx} style={{display:"flex",backgroundColor:"#dfdfc0", marginBottom:10, flexDirection:"row", alignItems:"center", justifyContent:"space-around",border: "1px dotted #d9d9d9"}}>
              <img src={item.urlImage} height={100} alt="" srcset="" />
              <div style={{fontSize:20,fontWeight:600}}>{item.name}</div>
          </div>
          
          </>)
      }else{
        return (     <>
          <div onClick={() => chooseTroopsTrain(item, idx)} key={idx} style={{display:"flex", marginBottom:10, flexDirection:"row", alignItems:"center", justifyContent:"space-around",border: "1px dotted #d9d9d9"}}>
              <img src={item.urlImage} height={100} alt="" srcset="" />
              <div style={{fontSize:20,fontWeight:600}}>{item.name}</div>
          </div>
          
          </>)
      }

     })
     :
     <button onClick={setTroopsDB} className={styles.btnGetVillages}>Get Troops</button>
     }
     {troopsDb[getTroops] !== undefined && JSON.parse(troopsDb[getTroops]) !== null && JSON.parse(troopsDb[getTroops]).length !== 0 &&
     <>
          <div style={{display:"flex", flexDirection:"row", justifyContent:"space-around", border: "1px dotted rgb(217, 217, 217)", padding:10, marginBottom:10}}>
          <label style={{fontSize:22, fontWeight:600}}>
            <input
              type="radio"
              value="100%"
              checked={selectedOption === '100%'}
              onChange={handleOptionChange}
              style={{ transform: 'scale(2.0)', marginRight: '10px' }}
            />
            100%
          </label>
          <label style={{fontSize:22, fontWeight:600}}>
            <input
              type="radio"
              value="50%"
              checked={selectedOption === '50%'}
              onChange={handleOptionChange}
              style={{ transform: 'scale(2.0)', marginRight: '10px' }}
            />
            50%
          </label>
          <label style={{fontSize:22, fontWeight:600}}>
            <input
              type="radio"
              value="33%"
              checked={selectedOption === '33%'}
              onChange={handleOptionChange}
              style={{ transform: 'scale(2.0)', marginRight: '10px' }}
            />
            33%
          </label>
          </div>
          <div style={{fontSize:20,display:"flex", flexDirection:"row", justifyContent:"space-around", border: "1px dotted rgb(217, 217, 217)", padding:10, fontWeight:600}}>
           {troopsDb.timer_train !== 0 && troopsDb.timer_train_show > 0 && millisToMinutesAndSeconds(troopsDb.timer_train_show)}
          </div>
          {console.log(troopsDb)}
       {troopsDb.timer_train === 0 ? <button onClick={sendTroopsTrainFetch} className={styles.teachButton}>Train</button>: <button onClick={sendTroopsTrainFetchStop} className={styles.stopteachButton}>stop Train</button>}
       </>
     }

    </div>
  )
}

export default Troops;
