import React,{useState,useEffect,useContext} from "react"
import styles from "./accounts.module.css"
import { useNavigate,Link } from 'react-router-dom';
import {fetchData, generateRandomToken}  from '../api'
function AccountList({item,connectUserStatusprop,setArrowBackStatus,set_security_bot}) {

    const [startX, setStartX] = useState(null);
    const [isSwiping, setIsSwiping] = useState(false);
    const [isSwipingDeleteButton, setIsSwipingDeleteButton] = useState(false);
    const [connectUserStatus, setConnectUserStatus] = useState('');
    const [showStatusConnectBot, setShowStatusConnectBot] = useState('')
    let navigate = useNavigate();


    useEffect(() => {
      if(item.status_connect === "connect good" || item.status_connect === 'bot stop' || item.status_connect === 'bot error' || item.status_connect === 'bot work'){
        setConnectUserStatus(100)
      }else{
        setConnectUserStatus(connectUserStatusprop)
      }
    },[connectUserStatusprop])

    useEffect(() => {
     switch (item.status_connect) {
      case "connect good":
        setShowStatusConnectBot('bot can connect!')
        break;
      case "bot stop":
        setShowStatusConnectBot('bot stop!')
        break;
      case "bot error":
        setShowStatusConnectBot('bot stop!')
        break;
      case "bot work":
        setShowStatusConnectBot('bot working...')
        break;
      case "bot restart":
        setShowStatusConnectBot('bot wait status...')
        break;
      default:
        break;
     }
    },[])

    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
        setIsSwiping(true);
      };
    
    const handleTouchMove = (e) => {
      if (!startX || !isSwiping) return;
  
      const currentX = e.touches[0].clientX;
      const difference = startX - currentX;
        // console.log(difference)
      // Ваш код обработки свайпа
      if (difference > 8) {
        // Свайп влево
        console.log('Swiped left!');
        setIsSwipingDeleteButton(true)
        // Добавьте здесь логику или вызовите функцию при свайпе влево
      }
      if (difference < -8 && 0 >= difference) {
        // Свайп влево
        console.log('Swiped left!');
        setIsSwipingDeleteButton(false)
        // Добавьте здесь логику или вызовите функцию при свайпе влево
      }
  
      // Сбросить начальную позицию для следующего свайпа
      setStartX(null);
    };
    const handleTouchEnd = () => {
      setIsSwiping(false);
    };

    const openBrowser = async() => {
      localStorage.removeItem('village')
      if(item.status_connect === 'connect good' || item.status_connect === 'bot stop' || item.status_connect === 'bot error'){
        localStorage.setItem("token_Account_bot", item.token)
        setArrowBackStatus('/travianbot')
        fetchData('openBrowserWithUser',{token:item.token,proxy:item.proxy})
        navigate('/travianbot')
        
      }else{
        if(item.status_connect === 'bot work'){
          setArrowBackStatus('/travianbot')
          navigate('/travianbot')
        }else{
          alert('wait, bot connect to server')
        }
        
      }
    }
    const deletAccount = async() => {
      await fetchData('deleteAccount',{token:item.token})
      window.location.reload()
    }
    const refreshBrowser = async() => {
      await fetchData('refreshBrowser',{token:item.token})
      window.location.reload()
    }
    return (
        <div 
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        className={styles.wrapperWithDeleteButton}
    >
        <div onClick={() => openBrowser()} className={styles.accountsWrapper}>
            <img className={styles.accountsimg} src="/iconAccount.png" alt="iconAccount" />
            <div style={{position:'relative'}}>
              <div style={{position:"absolute", top:-13, zIndex:100}}>
                <div className={`${styles.accountsTextServer} ${styles.accountsTextStyle}`}>{item.server}</div>
              </div>
                <div className={styles.accountsTextStyle}>{item.login}</div>
                <div>
                    <div style={{height:10, width:connectUserStatus, backgroundColor:"black",fontSize:12, color:'white', display:"inline-block", marginRight:5}}></div>
                    <span style={{fontSize:14, color:'black'}}>{connectUserStatus}%</span><br/>
                    <span style={{fontSize:12, color:'white', backgroundColor:"black", padding:1}}>{showStatusConnectBot}</span>
                </div>
                
            </div>
        </div>
        {isSwipingDeleteButton && <div style={{flexDirection:"row", display:"flex", justifyContent:"space-around",  border:"0px solid black"}}><img onClick={refreshBrowser}  width={40} height={42} src="/turnoff.svg" alt="" /> <img onClick={deletAccount} height={42} src="/deleteBasket.svg" alt="" />  </div>}
      </div>
    )
}
{/* <img onClick={() => set_security_bot(true)}  width={40} height={42} src="/bot.svg" alt="" /> */}
export {AccountList};