import React,{useState,useEffect,useContext} from "react"
// import logo from './logo.svg';
import {Context } from "../../context"
import styles from "./accounts.module.css"
import {Link} from 'react-router-dom'
import {fetchData, generateRandomToken}  from '../api'
import {AccountList} from "./AccountList"
function Accounts({setArrowBackStatus, set_security_bot, security_bot}) {
  const {socket} = useContext(Context);
  const [connectUserStatus, setConnectUserStatus] = useState(0)
  const [connectUserStatusLength, setConnectUserStatusLength] = useState(0)
  const [usersLoginBot, setUsersLoginBot] = useState([])

  socket.on('result connect user',(data) => {
    console.log("socket data", data)
    if(data == '100'){
      window.location.reload()
    }
    setConnectUserStatus(Number(data))
  })
  socket.on('all users loginbot',(data) => {
    console.log("socket data", data)
    setUsersLoginBot(data)
  })
   

    useEffect(() =>{
      const checkOrGetToken = async () => {
        const loginbot = localStorage.getItem("loginbot");
        if(loginbot){
            socket.emit("create room", loginbot)
            const getAllUsers = await fetchData("getUsersLoginbot",{loginbot})
            // console.log('getAllUsers', getAllUsers)
            setUsersLoginBot(getAllUsers)
            setConnectUserStatusLength(getAllUsers.length - 1)
        }else{
            const loginbotfetch = generateRandomToken(16)
            localStorage.setItem("loginbot", loginbotfetch)
            socket.emit("create room", loginbotfetch)
        }
      }
      checkOrGetToken()
      // console.log('userAgent', window.navigator.userAgent)
      // alert(window.navigator.platform)
      // console.log("languages", window.navigator.languages);
      // console.log("userAgentData", window.navigator.userAgentData.brands[0]);
      // console.log("userAgentData", window.navigator.userAgentData.brands[1]);
      // console.log("userAgentData", window.navigator.userAgentData.brands[2]);
      // console.log("window.navigator.userAgentData.platform", window.navigator.userAgentData.platform);
      // console.log("window.navigator.userAgentData.mobile", window.navigator.userAgentData.mobile);
      // alert(window.navigator.userAgentData)
      // const screenWidth = window.innerWidth;
      // const screenHeight = window.innerHeight;

    
     
    },[])

    return (
        <div>
            {!security_bot && usersLoginBot.map((item, idx) => {   
                return (
                  <AccountList setArrowBackStatus={setArrowBackStatus} set_security_bot={set_security_bot} connectUserStatusprop={connectUserStatus} item={item} key={idx}/>
                )
            })}
            {!security_bot && <div className={styles.accountsFixBottomButton}>
                <Link className={styles.accountsFixBottomButtonStyle} to='/registerUser'>Add account</Link>
            </div>}
            {security_bot && <div>
                <div style={{backgroundColor:"black", color:"white"}}>User agent: {window.navigator.userAgent}</div>
                <div style={{backgroundColor:"black", color:"white", marginTop:4}}>Platform: {window.navigator.platform}</div>
                <div style={{backgroundColor:"black", color:"white", marginTop:4}}>AcceptLanguage: {window.navigator.languages.join(' ')}</div>
                <div style={{backgroundColor:"black", color:"white", marginTop:4}}>AcceptLanguage: {window.navigator.userAgentMetadata}</div>
            </div>}
        </div>
    );
  }
  
  export default Accounts;
  