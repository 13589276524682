import React,{useState,useContext} from 'react';
import styles from './modal.module.css'
import { useNavigate } from 'react-router-dom';
import {fetchData, generateRandomToken}  from '../api'
import Raids from './raids/Raids';
import ResourseFields from './buildVillages/ResourseFields';
import CenterVillage from './buildVillages/CenterVillage';
import Troops from './troops/Troops';
function Modal({typeOpenModal,buildProps,token,selectVillage,setTypeOpenModal,resourseFields,openModelFunc}) {
  let navigate = useNavigate();
  const deleteWaitBuildDB = async (token, village,status, idDB) => {
    const result = await fetchData("deleteWaitBuildDB", {token,village,status,idDB})
    if(result){
      setTypeOpenModal("")
      setTimeout(() => {
        setTypeOpenModal(typeOpenModal)
      },1000)
    }
  } 
  
  const handleActionChange = (event) => {
    setTypeOpenModal(event.target.value);
  };
  switch (typeOpenModal) {
    case 'raids':
      return(
        <>
         <Raids token={token}/>
        </>
      )
    case 'buildResourseFields':
      return(
        <>
         <ResourseFields openModelFunc={openModelFunc} resourseFields={resourseFields} deleteWaitBuildDB={deleteWaitBuildDB} buildProps={buildProps} token={token} selectVillage={selectVillage}/>
        </>
      )
    case 'troops':
      return(
        <>
         <Troops openModelFunc={openModelFunc} resourseFields={resourseFields} deleteWaitBuildDB={deleteWaitBuildDB} buildProps={buildProps} token={token} selectVillage={selectVillage}/>
        </>
      )
    case 'buildcenterVillage':
      return(
        <>
         <CenterVillage deleteWaitBuildDB={deleteWaitBuildDB} buildProps={buildProps} token={token} selectVillage={selectVillage}/>
        </>
      )
    case 'modalRaidorBuild':
      return(
        <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column", height:"80vh"}}>
            {/* <div style={{marginBottom:20, fontSize:24}}>Выберите действие!</div> */}
            <select style={{height:80, width:200, textAlign:'center', fontSize:24}}
                    onChange={handleActionChange}
                    >
              <option disabled={true} selected={true}>Choose actions!</option>
              <option value={'buildcenterVillage'}>Build</option>
              <option value={'raids'}>Raids</option>
            </select>
        </div>
      )
    default:
      break;
  }

  // return (
  //   <>
  //     <Raids/>
  //     {/* <ResourseFields/> */}
  //     {/* <CenterVillage/> */}
  //     {/* <div className={styles.wrapperModalChooseButton}>
  //           <button className={styles.modalChooseButton}>Отправить рейды</button>
  //           <button className={styles.modalChooseButton}>Стройка</button>
  //     </div> */}
  //   </>
  // );
}

export default Modal;
