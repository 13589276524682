import React,{useState,useContext, useEffect} from 'react';
import styles from './raids.module.css'
import {fetchData, generateRandomToken}  from '../../api'
import { useNavigate } from 'react-router-dom';
function millisToMinutesAndSeconds(millis) {
    let minutes = Math.floor(millis / 60000);
    let seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + "min" + " : " + (seconds < 10 ? '0' : '') + seconds + "sec";
  }

function Raids({token}) {
    console.log(token)
    let navigate = useNavigate();
    const [farmList, setFarmList] = useState([])
    const [farmListStatus, setFarmListStatus] = useState([])
    const [startTimer, setStartTimer] = useState(0)
    const [finishTimer, setFinishTimer] = useState(0)
    const [botRaidsStatus, setBotRaidsStatus] = useState(null)
    const [timerShow, setTimerShow] = useState(0)
    const getFarmList = async() => {
       const result = await fetchData("getFarmList", {token})
    //    console.log('result',result)
       if(result[0].farm_list !== null){
        setFarmList(result[0].farm_list.split(','))
        setFarmListStatus(result[0].farm_list_status.split(','))
        setBotRaidsStatus(result[0].bot_raids_status)
        setTimerShow(result[0].next_timer_raids)
       }
       
    }

    useEffect(() => {
        getFarmList()
    },[])

    const handleCheckboxChange = async (idx) => {
        let newArrayStatusRaids = [...farmListStatus]
        if(newArrayStatusRaids[idx] === "true"){
            newArrayStatusRaids[idx] = "false"
        }else{
            newArrayStatusRaids[idx] = "true"
        }
        // setFarmListStatus(newArrayStatusRaids)
        
        const result = await fetchData("updateStatusFarmList", {token,"updateStatusRaids":newArrayStatusRaids.join(',')})
        if(result.status === "updateRaidsOK"){
            getFarmList() 
        }
    }

    const handleStartTimerChange = (event) => {
        // setStartTimer(event.target.value);
        // console.log('event', event.target.name)
        switch (event.target.name) {
            case "start time":
                setStartTimer(event.target.value);
                break;
            case "finish time":
                setFinishTimer(event.target.value);
                break;
            default:
                break;
        }
      };

    const startWorkBotSend = async () => {
        if(botRaidsStatus === "false"){
            if(Number(startTimer) >= Number(finishTimer)){
                alert('Not correct timer')
                return
            }
            if(3 > Number(startTimer)){
                alert('Start timer 4 minutes')
                return
            }
        }

        let status
        if(botRaidsStatus === "false"){
            status = "true"
        }else{
            status = "false"
        }
        const result = await fetchData("updateTimerForRaids", {token,startTimer,finishTimer,status})
        console.log(result)
        if(result.status === "updateRaidsTimerOK"){
            getFarmList()
        }
        
    }

    const setFarmListFetch = async () =>{
        const result = await fetchData("setFarmListFetch", {token})
        navigate('/')
    }


  return (
      <div>
        {/* {console.log('botRaidsStatus', botRaidsStatus)} */}
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
        {botRaidsStatus === null && <div style={{margin:"0 auto"}}> <button onClick={setFarmListFetch} className={styles.btnGetVillages}>Get data</button></div>}
        {botRaidsStatus === "false" ?
                <div style={{display:"flex", flexDirection:"row"}}>
                <input className={styles.timerInput} onChange={handleStartTimerChange} name="start time" type="number" placeholder="minutes"/>
                <input className={styles.timerInput} onChange={handleStartTimerChange} name="finish time" type="number" placeholder="minutes"/>
                </div>
                 :
                 botRaidsStatus !== null && <div className={styles.wrapperTimer}>{millisToMinutesAndSeconds(timerShow)}</div> 
                 }
        {botRaidsStatus !== null && botRaidsStatus === "false" ?
        <button onClick={startWorkBotSend} className={styles.startRaidTimer}>Start</button>
         :
         botRaidsStatus !== null && <button onClick={startWorkBotSend} className={styles.stopRaidTimer}>Stop</button>
         }
        </div>

           <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                    {farmList.map((item,idx) => {
                        let isChecked = farmListStatus[idx]
                        return (
                            <label key={idx} style={{display:"flex", flexDirection:"row", justifyContent:"space-between",alignItems:"center", width:"100%", marginBottom:10,border: "1px solid", padding: "14px"}}>
                            <div style={{fontSize:20, marginLeft:15}}>{item}</div>
                            <input
                                type="checkbox"
                                style={{height:20,width:70}}
                                // Значение атрибута "checked" зависит от состояния isChecked
                                checked={isChecked === "true" ? true : false}
                                // Функция для обработки события изменения состояния чекбокса
                                onChange={() =>handleCheckboxChange(idx)}
                            />
                        </label>
                        )
                    })}
           </div>
      </div>
  );
}

export default Raids;


  
//   function randomInteger(min:any, max:any) {
//     // получить случайное число от (min-0.5) до (max+0.5)
//     let rand = min - 0.5 + Math.random() * (max - min + 1);
//     return Math.round(rand);
//   }