import React,{useState,useContext,useEffect} from 'react';
import styles from './resourse.module.css'
import {fetchData, generateRandomToken}  from '../../api'
function CenterVillage({buildProps,token,selectVillage, deleteWaitBuildDB}) {
    const [nowLevel, setNowLevel] = useState(Number(buildProps[0]))
    const [levelBuild, setLevelBuild] = useState(nowLevel)
    const level = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
    const [buildHouseStatus, setBuildHouseStatus] = useState([])
    const chooseLevelResourseFields = (buildLevel) => {
        setLevelBuild(level[buildLevel])  
    }

    useEffect(() => {
        fetchResultBuild()
        // console.log(buildProps)
    },[])

    const fetchResultBuild = async () =>{
        const result = await fetchData("getBuildCenterVillageAndResourseFields",{village:selectVillage,token})
        // console.log('result', result)
        setBuildHouseStatus(result)
        
    }

    const fetchStartBuildResourseFields = async () => {
        const typeResourseFields = buildProps[1];
        
        const link = buildProps[2];
        const result_status = await fetchData("addHouseInBuild", {typeResourseFields,nowLevel,complete_level:levelBuild,token,village:selectVillage,link}) 
        if(result_status.status === "add_complete"){
            fetchResultBuild()
        }  
    }
  return (
    <div>
        <div style={{display:'flex', justifyContent:"space-around", alignItems:"center", marginBottom:10}}>
            <div style={{display:'inline-block',textAlign:'center',marginLeft:10, height:512, overflow:"hidden", overflowY:"scroll"}}>
                {level.map((item,idx) => {
                    if(nowLevel >= item){
                        return <div style={{color:'black',backgroundColor: "rgba(0,0,0,0.2)", fontSize:30, border:"0px solid black",borderRadius:1, padding:7}} key={idx}>{item}</div>
                    }else{
                        if(levelBuild === item) {
                            return <div onClick={() => chooseLevelResourseFields(idx)} style={{color:'black', fontSize:30, border:"1px solid black",borderRadius:1, padding:7}} key={idx}>{item}</div>
                        }else{
                            return <div onClick={() => chooseLevelResourseFields(idx)} style={{color:'black', fontSize:30, border:"0px solid black",borderRadius:1, padding:7}} key={idx}>{item}</div>
                        }
                        
                    }
                
                }).reverse()}
            </div>
            <div>
                <div className={styles.wrapperInfoBoxResourse}>
                    <span style={{position:"absolute"}} >{buildProps[1]}</span>
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center",height:"100%"}}>
                        <span style={{fontSize:28}}>{nowLevel}</span>
                        <img  width={25} height={22} src="/arrow.png" alt="" />
                        <span style={{fontSize:28}}>{levelBuild}</span>
                    </div>
                </div>
                <button onClick={fetchStartBuildResourseFields} className={styles.startBuild}>Build</button>
            </div>
        </div>
        {buildHouseStatus.map((item,idx) => {
            // console.log(item)
            let img = ""
            if(item.type_field.length === 1){
                img = "/" + item.type_field + ".png"
            }
            return (
                <ul onClick={() => deleteWaitBuildDB(item.token, item.village, item.status, item.id)} key={idx} style={{display:"flex", flexDirection:"row", justifyContent:"space-around", alignItems:"center",border:"1px solid black", marginBottom:2,padding:8}}>
                    {item.type_field.length != 1 
                    ? <li style={{fontSize:18}}>{item.type_field}</li>
                    : <li><img  width={15} height={12} src={img} alt="" /></li>}
                    <li style={{fontSize:16}}>{item.village}</li>
                    <li style={{fontSize:20}}>{item.now_level}</li>
                    <li><img  width={25} height={22} src="/arrow.png" alt="" /></li>
                    <li style={{fontSize:20}}>{item.complete_level}</li>
                    {/* <li style={{fontSize:20}}>20min: 23sec</li> */}
                    <li>{item.status}</li>
            </ul> 
            )
        })}
        </div>

  );
}

export default CenterVillage;
