import React,{useState,useContext,useEffect} from 'react';
// import logo from './logo.svg';
import styles from './register.module.css'
import { useNavigate,Link } from 'react-router-dom';
import {fetchData, generateRandomToken}  from '../api'
// import {Context } from "../../context"
function RegisterPage() {
  const [loginUser,setLoginUser] = useState({login:"",password:"",proxy:"", server:""})
  // const {socket} = useContext(Context);
  const [server_travian, set_server_travian] = useState([])
  const [proxy_travian, set_proxy_travian] = useState([])
  const navigate = useNavigate()
  const getUser = (e) => {
    const name = e.target.name
    const value = e.target.value
    setLoginUser((prev) => {
      return {...prev,[name]:value}
    })
  }
  const getStatusLoginbot = async () => {
    const result = await fetchData("userloginbotstatus", {'loginbot':localStorage.getItem('loginbot')})
    if(result.length){
      set_server_travian(result[0].server)
      set_proxy_travian(result[0].proxy)
    }
    console.log('userloginbotstatus', result)


  }
  useEffect(() => {
    const loginbot = localStorage.getItem('loginbot')
    setLoginUser((prev => {
      return {...prev, ['loginbot']:loginbot}
    }))
    getStatusLoginbot()
  },[])

  const sendUser = async () => {
    const result = await fetchData("createUser", loginUser)
    // console.log(loginUser)
    navigate('/')
  }
 
  return (
      <div className={styles.formWrapper}>
        <input className={styles.formWrapperInput} onChange={getUser} name="login" type="text" placeholder="login"/>
        <input className={styles.formWrapperInput} onChange={getUser} name="password" type="text" placeholder="password"/>
        <select className={styles.formWrapperInput} onChange={getUser} name="server">
          <option selected disabled>server</option>
          {server_travian != null && server_travian != [] && server_travian != undefined && server_travian.map((item, idx) => {
            return( 
                <option key={idx} value={item}>{item}</option>
            )
          })}
        </select>
        <select className={styles.formWrapperInput} onChange={getUser} name="proxy">
          <option selected disabled>proxy</option>
          {proxy_travian != null && proxy_travian !=[] && server_travian != undefined && proxy_travian.map((item, idx) => {
            return(
                <option key={idx} value={item}>{item}</option>
            )
          })}
        </select>
        <button onClick={sendUser} className={styles.addAccountBtn}>Add account</button>
        <p>{localStorage.getItem('loginbot')}</p>
      </div>
  );
}

export default RegisterPage;
