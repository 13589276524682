
// import logo from './logo.svg';
import styles from "./header.module.css"
import { useEffect, useState } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import {fetchData, generateRandomToken,millisToMinutesAndSeconds}  from '../api'
function Header({arrowBackStatus,answer_travianbot_socket,set_socket_model,socket}) {
    const [showArrow, setShowArrow] = useState(true)
    const [arrowBackLink, setArrowBackLink] = useState("")
    let navigate = useNavigate();
    
    useEffect(() => {  
        // navigate('/')
        //  console.log('arrowBackStatus',arrowBackStatus)
         switch (arrowBackStatus) {
            case '/travianbot':
                setArrowBackLink('/')
                break;
            case '/open':
                setArrowBackLink('/travianbot')
                break;
            default:
                setArrowBackLink('/')
                break;
         }
    },[arrowBackStatus])



    const buttonUpdate = async () => { 
        // console.log('useNavigate', window.location)
        if(window.location.pathname === "/travianbot"){
            const getUserVillagesAddDB = await fetchData("getUserVillagesAddDB",{'token':localStorage.getItem("token_Account_bot"), "selectVillage":localStorage.getItem('village')})
            if(getUserVillagesAddDB){
                alert('add action update village')
            }
        }
        if(window.location.pathname === '/raids'){
            await fetchData("setFarmListFetch", {token:localStorage.getItem("token_Account_bot")})
            alert('add action update farm list')
        }
        if(window.location.pathname === '/troops'){
            await fetchData("setTroopsDB", {token:localStorage.getItem('token_Account_bot'), village:localStorage.getItem('village')})
            alert('add action update troops')
        }
    }
    const changeStatusBotModel = async() => {
        socket.emit('joinRoom', localStorage.getItem('token_Account_bot'))
        set_socket_model((prev => {
            return !prev
        }))
        
    }
    return (
        <div className={styles.headerWrapper}>
            {showArrow && <a href={arrowBackLink}><img className={`${styles.headerImg} ${styles.headerImgArrow}`} src="/arrowback.png" alt="" /></a>}
            {/* <img onClick={() => setRegisterPageModuleStatus(false)} className={`${styles.headerImg} ${styles.headerImgArrow}`} src="/arrowback.png" alt="" /> */}
            <img onClick={changeStatusBotModel} className={styles.headerImg} src="/icon-removebg.png" alt="" />
            <div className={styles.headerTitle}>{answer_travianbot_socket}</div>
            <div onClick={buttonUpdate} style={{position:"absolute", right:"8px"}}><img  width={40} height={42} src="/refresh.svg" alt="" /></div>
        </div>
    );
  }
  
  export default Header;
  